import styled from "styled-components";
import { FaGithub } from "react-icons/fa";
import { SiBootstrap, SiCsharp, SiCss3, SiDocker, SiGit, SiHtml5, SiJavascript, SiKonva, SiMongodb, SiPwa, SiPython, SiReact, SiTypescript, SiUnity, SiVite, SiVuedotjs } from "react-icons/si";

import LinksList from "./LinksList";
import { breakpoints } from "../breakpoints";

function Main() {
    const icons = [
        [<SiTypescript />, 'typescript icon'],
        [<SiJavascript />, 'javascript icon'],
        [<SiHtml5 />, 'html5 icon'],
        [<SiCss3 />, 'css3 icon'],
        [<SiCsharp />, 'c sharp icon'],
        [<SiUnity />, 'unity framework icon'],
        [<SiPython />, 'python icon'],
        [<SiVuedotjs />, 'vue icon'],
        [<SiReact />, 'react icon'],
        [<SiDocker />, 'docker icon'],
        [<SiGit />, 'git icon'],
        [<SiVite />, 'vite icon'],
        [<SiPwa />, 'pwa icon'],
        [<SiMongodb />, 'mongodb icon'],
        [<SiKonva />, 'konva icon'],
        [<SiBootstrap />, 'bootstrap icon'],
    ]

    return (
        <StyledMain>
            <section>
                <article>
                    <h2>
                        About Me
                    </h2>
                    <p>
                        I'm a frontend developer for web applications. My mission is to put my technical and organizational skills to use to implement and realize other people's vision, the more far fetched and out there the better. Basically, <strong>I love writing code to make things happen</strong>.
                    </p>
                    <p>
                        I started fiddling with javascript, html and css in 2004, as a teenage hobby, and never stopped. Since then, I've been broadening the range of my technical skills by dipping my toe in backend and game development as well.
                    </p>
                    <StyledUL>
                        {
                            icons.map(([icon, description], i) => (
                                <li key={"icon" - i}>
                                    <span aria-hidden="true">{icon}</span>
                                    <span className="accessibility-only">{description}</span>
                                </li>
                            ))
                        }
                    </StyledUL>
                </article>
                <article>
                    <h2>
                        Projects
                    </h2>
                    <p>
                        A few examples and extracts of past work.
                    </p>
                    <section>
                        <ProjectsArticle>
                            <h3>
                                Solutions
                            </h3>
                            <LinksList
                                elements={[
                                    [
                                        <a href="https://github.com/impproductions/aoc-2021-day8" target="_blank" rel="noopener noreferrer">AoC 2021 day 8</a>,
                                        'a lazy, creative solution to a trivial problem',
                                        ''
                                    ],
                                    [
                                        <a href="https://github.com/impproductions/ts-async-object-creation" target="_blank" rel="noopener noreferrer">TS: Async class construction</a>,
                                        'a typescript exercise in generics, inheritance and type inference',
                                        ''
                                    ],
                                    [
                                        <a href="https://github.com/impproductions/unity-circular-dots" target="_blank" rel="noopener noreferrer">Circular-ish fence</a>,
                                        'connecting dots to form a circle in Unity',
                                        ''
                                    ],
                                ]}
                            ></LinksList>
                        </ProjectsArticle>
                        <ProjectsArticle>
                            <h3>
                                Proofs of Concept
                            </h3>
                            <LinksList
                                elements={[
                                    [
                                        <a href="https://react-cylinder.onrender.com" target="_blank" rel="noopener noreferrer">Cylindrical elements</a>,
                                        'a react component to wrap an html element around a cylinder',
                                        <a href="https://github.com/impproductions/react-cylinder" className="icon" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                                    ],
                                    [
                                        <a href="https://react-nested-squares.onrender.com" target="_blank" rel="noopener noreferrer">Nested squares</a>,
                                        'a geometric visual exercise',
                                        <a href="https://github.com/impproductions/react-nested-squares" className="icon" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                                    ],
                                    [
                                        <a href="https://react-cube.onrender.com" target="_blank" rel="noopener noreferrer">Cubic Website</a>,
                                        'a dummy website built on the six faces of a rotating cube',
                                        <a href="https://github.com/impproductions/react-cube" className="icon" target="_blank" rel="noopener noreferrer"><FaGithub /></a>
                                    ],
                                ]}
                            ></LinksList>
                        </ProjectsArticle>
                        <ProjectsArticle>
                            <h3>
                                Tools
                            </h3>
                            <LinksList
                                elements={[
                                    [
                                        <a href="https://spc-canary.onrender.com" target="_blank" rel="noopener noreferrer">Rider Editor</a>,
                                        'Vue3 PWA to create and maintain technical riders for live shows (development version)',
                                        ''
                                    ],
                                ]}
                            ></LinksList>
                        </ProjectsArticle>
                    </section>
                </article>
            </section>
        </StyledMain>
    )
}

const StyledMain = styled.main`
    height: 100%;
    border-left: 1px solid var(--primary-color);
    border-top: 1px solid var(--primary-color);
    background-color: var(--surface-1);

    article {
        padding: 0.5rem;
        padding-left: 1rem;
        padding-right: 1rem;
    }
    
    article h2 {
        padding-left: 0.5rem;
    }
    article h3 {
    }

    article p {
        padding-left: 0.5rem;
    }
`;

const ProjectsArticle = styled.article`

`;
const StyledUL = styled.ul`
    margin-top: 1rem;
    display: flex;
    align-items: center;
    justify-content: space-around;
    list-style: none;
    flex-wrap: wrap;
    padding-left: 1rem;
    padding-right: 1rem;
    gap: 0.5rem;

    li {
        flex: 0 0;
    }
    
    @media screen and (${breakpoints.mobile}){
        li {
            flex: 0 0 15%;
        }
    }
`;

export default Main;